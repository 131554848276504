import React, { useContext,  useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataContext from '../Reusables/DataContext';
import { translations, getGroupName, getTopicName } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import './Grammar.css';

const PronunciationGroups = () => {
  
  const navigate = useNavigate();
  const { pronunciationGroups, fetchPronunciationGroups, pronunciationTopics, setPronunciationTopics, fetchPronunciationTopics, setPronunciationContent } = useContext(DataContext);
  const { selectedLanguage } = useLanguage();
  const [expandedTopicIndex, setExpandedTopicIndex] = useState(null); 

  useEffect(() => {    
    fetchPronunciationGroups();
  }, []);
  
  const handleGroupClick = (index, group) => {
    if (group.group_id > 3 && group.group_id < 8) {        
      setPronunciationContent([]);      
      navigate('/pronunciation-topic', { state: {  groupId: group.group_id, groupName: getGroupName(group, selectedLanguage) } });
    } else {
      setExpandedTopicIndex(index === expandedTopicIndex ? null : index);
      setPronunciationTopics([]);
      fetchPronunciationTopics(group.group_id); 
    }    
  };

  const handleClickPronunciationTopic = (topic) => {   
    setPronunciationContent([]);    
    navigate('/pronunciation-topic', { state: { topicId : topic.topic_id , topicName: getTopicName(topic, selectedLanguage) } });
  };

  return (
    <div className="study-page">
      <div className="top-row">
        <button onClick={() => navigate(-1)} className="back-button"> <img src={`/Icons/arrow-left.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].back} />  </button>
        <h3>{translations[selectedLanguage].Pronunciation}</h3>
        <div><BurgerMenuComponent /></div>  
      </div> 
      <div className="study-body">
        {pronunciationGroups.map((pronunciationGroup, index) => (
            <div key={pronunciationGroup.group_id}  className="topics">            
              <button className="grammar-button" onClick={() => handleGroupClick(index, pronunciationGroup)} >
                <div style={{marginRight:"auto"}}>{getGroupName(pronunciationGroup, selectedLanguage)}  </div>
                <div style={{marginLeft:"auto"}}>
                {(pronunciationGroup.group_id > 3 && pronunciationGroup.group_id < 8) ? 
                  <img src="/Icons/arrow-right.svg" alt={translations[selectedLanguage].next} className="arrow-right" />      
                  :
                  (expandedTopicIndex === index) ? ( 
                    <img src="/Icons/arrow-up.svg" alt={translations[selectedLanguage].collapse} />
                  ) : (
                    <img src="/Icons/arrow-down.svg" alt={translations[selectedLanguage].expand} />
                  )
                }
                </div>
              </button>
              {expandedTopicIndex === index && ( 
                <div>
                  {pronunciationTopics.map((pronunciationTopic, index) => (
                    <div key={pronunciationTopic.topic_id} className="grammar-topic-options">            
                      <button className="grammar-option" onClick={() => handleClickPronunciationTopic(pronunciationTopic)} >
                        <span>  {getTopicName(pronunciationTopic, selectedLanguage)}</span>
                        <img src="/Icons/arrow-right.svg" alt={translations[selectedLanguage].next} className="arrow-right" />                  
                      </button>
                    </div>            
                  ))}                 
                </div>
              )}
  
              {index < pronunciationGroups.length - 1 && <hr />}
            </div>            
        ))}
      </div> 
    </div>
  );
};

export default PronunciationGroups;
