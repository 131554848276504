import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../Reusables/LanguageProvider';
import { translations, highlightMatches } from '../Reusables/Utilities';
import BarChart from '../Reusables/BarChart';
import { useUserAnswers } from '../Practice/UserAnswers';
import DataContext from '../Reusables/DataContext';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import './MyReports.css';

const MyReports = () => {
    let navigate = useNavigate();
    const { selectedLanguage } = useLanguage();
    const {userReports, filteredReports, setFilteredReports } = useUserAnswers(); 
    const [clickFilter, setClickFilter] = useState(false);
    // const [progressBar, setProgressBar] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [selectedLevels, setSelectedLevels] = useState([]);
    // const [filteredReports, setFilteredReports] = useState(userReports); // Initialize with all reports
    const [weekly, setWeekly] = useState(false);
    const [barClick, setBarClick] = useState(false);
    const [reportClick, setReportClick] = useState(false);
    const [filteredReports2, setFilteredReports2] = useState(false);
    const { fetchTopicFromEnglishTopic } = useContext(DataContext);
    const [report, setReport] = useState([]);

    useEffect(() => {
        handleReset();
      }, []);
    

    const handleClickFilter = () => {
        setClickFilter(!clickFilter);
      };

      const handleSkillToggle = (skill) => {
        setSelectedSkills((prevSkills) =>
            prevSkills.includes(skill)
                ? prevSkills.filter((s) => s !== skill)
                : [...prevSkills, skill]
        );
    };
    
    const handleLevelToggle = (level) => {
        setSelectedLevels((prevLevels) =>
            prevLevels.includes(level)
                ? prevLevels.filter((l) => l !== level)
                : [...prevLevels, level]
        );
    };
    
    const handleSearch = () => {
        let filtered = [...userReports];
        
        // Normalize dates for comparison (remove time component)
        const normalizeDate = (date) => new Date(new Date(date).toDateString());

        const normalizedFromDate = fromDate ? normalizeDate(fromDate) : null;
        const normalizedToDate = toDate
            ? new Date(normalizeDate(toDate).getTime() + 24 * 60 * 60 * 1000) // Add 1 day
            : null;

        // Filter by date
        if (normalizedFromDate) {
            filtered = filtered.filter((report) => normalizeDate(report.date) > normalizedFromDate);
        }
        if (normalizedToDate) {
            filtered = filtered.filter((report) => normalizeDate(report.date) <= normalizedToDate);
        }    

        // Filter by skills
        if (selectedSkills.length > 0) {
            filtered = filtered.filter((report) => selectedSkills.includes(report.skillID));
        }
    
        // Filter by levels
        if (selectedLevels.length > 0) {
            filtered = filtered.filter((report) => selectedLevels.includes(Number(report.level)));
        }
    
        setFilteredReports(filtered);
        setClickFilter(false);
        setBarClick(false);
        setReportClick(false);
    };
    
    const handleReset = () => {
        setWeekly(false);
        setBarClick(false);
        setReportClick(false);
        setFromDate('');
        setToDate('');
        setSelectedSkills([]);
        setSelectedLevels([]);
        setFilteredReports(userReports); // Reset to all reports
    }; 

    const calculateAverageScore = (reports) => {
        if (reports.length === 0) return 0;
        const totalScore = reports.reduce((acc, report) => acc + Number(report.score), 0);
        return (totalScore / reports.length).toFixed(2);
      };      

    const groupReportsByTimeline = (reports) => {
        const grouped = {};
      
        reports.forEach((report) => {
          const date = new Date(report.date);
      
          let key;
          if (weekly) {
            // Get the preceding Monday or the date itself if it's already a Monday
            key = getPreviousMonday(date).toISOString().split('T')[0];
          } else {
            // Group by month
            key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
          }
      
          if (!grouped[key]) grouped[key] = { reports: [], totalScore: 0, count: 0 };
          grouped[key].reports.push(report);
          grouped[key].totalScore += Number(report.score);
          grouped[key].count += 1;
        });
      
        return Object.entries(grouped).map(([key, data]) => ({
          period: key,
          averageScore: (data.totalScore / data.count).toFixed(2),
          reports: data.reports,
        }));
      };
      
      // Helper Function to Get the Preceding Monday
      const getPreviousMonday = (date) => {
        const targetDate = new Date(date);
        const day = targetDate.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
        const diff = day === 0 ? 6 : day - 1; // Calculate how many days to subtract
        targetDate.setDate(targetDate.getDate() - diff);
        return targetDate;
      };
      
            
    const handleBarClick = (reports) => {
        setFilteredReports2(reports);
        setBarClick(true);
        setReportClick(false);
    };
    const handleReportClick = (report) => {
        setReportClick(true);
        setReport(report);
    };

    // Component to handle asynchronous fetching and rendering
    const AsyncTopic = ({ englishTopic, selectedLanguage }) => {
        const [topic, setTopic] = useState('');
    
        useEffect(() => {
        const fetchTopic = async () => {
            const fetchedTopic = await fetchTopicFromEnglishTopic(
            englishTopic,
            selectedLanguage
            );
            setTopic(fetchedTopic || 'N/A'); // Set "N/A" if no topic is returned
        };
        fetchTopic();
        }, [englishTopic, selectedLanguage]);
    
        return <>{topic || 'Loading...'}</>;
    };
      
    return (
        <div className="study-page">
          <div className="top-row">
            <button onClick={() => navigate(-1)} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>        
            <h3>{translations[selectedLanguage].MyReports}</h3>
            <div><BurgerMenuComponent/></div>          
          </div>
          <div className="reports" >  
          <button
            className="filter-button"
            onClick={() => handleClickFilter()}
          >
            <img  src={`/Icons/filter.svg?${new Date().getTime()}`} alt="" />&nbsp; 
            <span>  {translations[selectedLanguage].Filters}</span>
            <span style={{marginLeft:"auto"}}>{clickFilter ? ( 
                  <img src={`/Icons/arrow-up.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].collapse} />
                ) : (
                  <img src={`/Icons/arrow-down.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].expand} />
                )}</span>
          </button>

          {clickFilter && (
             <div className="filter-dropdown">

            <h4>{translations[selectedLanguage].Datapreferences}</h4> 
            <div className="filter-data">
                <label className="toggle-container">
                    {translations[selectedLanguage].WeeklyProgress}
                    <div className="toggle-switch">
                        <input
                            type="checkbox"
                            checked={weekly}
                            onChange={(e) => setWeekly(e.target.checked)}
                        />
                        <span className="slider"></span>
                    </div>
                </label>
                    <small>{translations[selectedLanguage].DefaultIsMonthly}</small>                 
            </div>
            <hr/>

             <div className="filter-date">
                 <h4>{translations[selectedLanguage].Date}</h4>
                 <div className="date-row">
                    <div className="date-column">
                        <label>{translations[selectedLanguage].Fromdate}</label>
                        <input
                            type="date"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                        />
                    </div>
                    <div className="date-column">
                        <label>{translations[selectedLanguage].Todate}</label>
                        <input
                            type="date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                        />
                    </div>
                </div>
             </div>
             <hr />

             <div className="filter-levels">
                 <h4>{translations[selectedLanguage].Levels}</h4>
                 <button
                        className={`level-button ${selectedLevels.includes(1) ? 'selected' : ''}`}
                        onClick={() => handleLevelToggle(1)}
                    >1</button>
                 <button
                        className={`level-button ${selectedLevels.includes(2) ? 'selected' : ''}`}
                        onClick={() => handleLevelToggle(2)}
                    >2</button>
                 <button
                        className={`level-button ${selectedLevels.includes(3) ? 'selected' : ''}`}
                        onClick={() => handleLevelToggle(3)}
                    >3</button>
                 <button
                        className={`level-button ${selectedLevels.includes(4) ? 'selected' : ''}`}
                        onClick={() => handleLevelToggle(4)}
                    >4</button>
                 <button
                        className={`level-button ${selectedLevels.includes(5) ? 'selected' : ''}`}
                        onClick={() => handleLevelToggle(5)}
                    >5</button>
                 <button
                        className={`level-button ${selectedLevels.includes(6) ? 'selected' : ''}`}
                        onClick={() => handleLevelToggle(6)}
                    >6</button>
                 <button
                        className={`level-button ${selectedLevels.includes(7) ? 'selected' : ''}`}
                        onClick={() => handleLevelToggle(7)}
                    >7</button>
                 <button
                        className={`level-button ${selectedLevels.includes(8) ? 'selected' : ''}`}
                        onClick={() => handleLevelToggle(8)}
                    >8</button>
             </div>
             <hr />
             
             <div className="filter-skills">
                 <h4>{translations[selectedLanguage].Skills}</h4>
                 <label><input
                        type="checkbox"
                        checked={selectedSkills.includes(1)}
                        onChange={() => handleSkillToggle(1)}
                    />{translations[selectedLanguage].Speaking}</label>
                <label><input
                        type="checkbox"
                        checked={selectedSkills.includes(2)}
                        onChange={() => handleSkillToggle(2)}
                    />{translations[selectedLanguage].Writing}</label>
             </div>
             <hr />            

             <div className="filter-buttons">
                <button className="filter-reset-button"  onClick={handleReset}>{translations[selectedLanguage].Reset}</button>
                <button className="filter-search-button"  onClick={handleSearch}>{translations[selectedLanguage].Search}</button>
            </div>
         </div>)}

         <br/>
         <div>            
            <h3 style={{textAlign:'left'}}>{translations[selectedLanguage].AverageScore}: {calculateAverageScore(filteredReports)} %</h3>
            <br/><br/>
            <BarChart data={groupReportsByTimeline(filteredReports)} onBarClick={handleBarClick} />           
        </div>

        <br/><br/>{barClick && <table class="table-content">
          <thead>
            <tr>
              <th>{translations[selectedLanguage].Date}</th>
              <th>{translations[selectedLanguage].Level}</th>
              <th>{translations[selectedLanguage].Topic}</th>
              <th>{translations[selectedLanguage].Skill}</th>
              <th>{translations[selectedLanguage].Score}</th>
            </tr>
          </thead>
          <tbody>
            {filteredReports2.map((report, index) => (
              <tr key={index}>
                <td>{new Date(report.date).toISOString().split('T')[0]}</td>
                <td>{report.level}</td>
                <td>{selectedLanguage === report.selectedLanguage ? report.topic : <AsyncTopic
                  englishTopic={report.englishTopic}
                  selectedLanguage={selectedLanguage}
                />}</td>
                <td>{report.skillID === 1 ? translations[selectedLanguage].Speaking : translations[selectedLanguage].Writing}</td>
                {/* <td><button className="score-button" onClick={() => navigate('/report-detail', { state: { report: report } })}> */}
                <td><button className="score-button" onClick={() => handleReportClick(report)}>
                    {report.score} %
                  </button></td>                 
              </tr>
            ))}
          </tbody>
        </table>}

        <br/><br/>{reportClick && report.pairs.map((pair, pairIndex) => ( 
          <div key={pairIndex} className="result-box"> 
            <div className="line-1">                    
                <span className="label">{translations[selectedLanguage].Detected}:</span>
                <span className="content">{highlightMatches(pair.matchSuggestResponse, pair.userAnswer)}</span>
            </div>
            <div className="line-2">  
                <span className="label">{translations[selectedLanguage].Expected}:</span>
                <span className="content">{pair.matchSuggestResponse}</span>                   
            </div>
        </div>
        ))}
        </div>
        </div>
    );
}

export default MyReports;
