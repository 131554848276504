import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// TO COMMIT FROM A MICROSOFT PC TO LINUX SERVER (GITHUB CODE SHOULD BE LINUX SERVER)
// CHANGE axios.get('http://localhost:5005   TO  axios.get('/api
// AND UNCOMMENT THE BELOW CODE
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5005', // Use .env file for different environments
});

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  // const [noviceThemes, setNoviceThemes] = useState(() => {
  //   const storedThemes = localStorage.getItem('noviceThemes');
  //   return storedThemes ? JSON.parse(storedThemes) : [];
  // });
  // const [themes, setThemes] = useState(() => {
  //   const storedThemes = localStorage.getItem('themes');
  //   return storedThemes ? JSON.parse(storedThemes) : [];
  // });
  
  const [levels, setLevels] = useState(() => {
    const storedLevels = localStorage.getItem('levels');
    return storedLevels ? JSON.parse(storedLevels) : [{level_id: 1, level_name: "Level 1"}, {level_id: 2, level_name: "Level 2"},{level_id: 3, level_name: "Level 3"},{level_id: 4, level_name: "Level 4"},{level_id: 5, level_name: "Level 5"},{level_id: 6, level_name: "Level 6"},{level_id: 7, level_name: "Level 7"},{level_id: 8, level_name: "Level 8"}];
  });


  const [topics, setTopics] = useState(() => {
    const storedTopics = localStorage.getItem('topics');
    return storedTopics ? JSON.parse(storedTopics) : [];
  });

  const [allTopics, setAllTopics] = useState(() => {
    const storedAllTopics = localStorage.getItem('allTopics');
    return storedAllTopics ? JSON.parse(storedAllTopics) : [];
  });

  const [conversations, setConversations] = useState(() => {
    const storedConversations = localStorage.getItem('conversations');
    return storedConversations ? JSON.parse(storedConversations) : [];
  });

  const [sentencesForStudy, setSentencesForStudy] = useState(() => {
    const storedSentences = localStorage.getItem('sentencesForStudy');
    return storedSentences ? JSON.parse(storedSentences) : [];
  });
  // a boolean array to distinguish speaking and listening sentences
  const [sentencesForStudyForSpeak, setSentencesForStudyForSpeak] = useState(() => {
    const storedSentences = localStorage.getItem('sentencesForStudyForSpeak');
    return storedSentences ? JSON.parse(storedSentences) : [];
  });

  const [sentencesForExercise, setSentencesForExercise] = useState(() => {
    const storedSentences = localStorage.getItem('sentencesForExercise');
    return storedSentences ? JSON.parse(storedSentences) : [];
  });

  const [exercises, setExercises] = useState(() => {
    const storedSentences = localStorage.getItem('exercises');
    return storedSentences ? JSON.parse(storedSentences) : [];
  });
  const [instructionForExercise, setInstructionForExercise] = useState(() => {
    const storedSentences = localStorage.getItem('instructionForExercise');
    return storedSentences ? JSON.parse(storedSentences) : '';
  });

  const [endWithAI, setEndWithAI] = useState(false);
  const [grammarGroups, setGrammarGroups] = useState([]);
  const [grammarTopics, setGrammarTopics] = useState([]);
  const [grammarContent, setGrammarContent] = useState('');
  const [pronunciationGroups, setPronunciationGroups] = useState([]);
  const [pronunciationTopics, setPronunciationTopics] = useState([]);
  const [pronunciationContent, setPronunciationContent] = useState('');
  const [myVocab, setMyVocab] = useState([]);

  useEffect(() => {
    localStorage.setItem('conversations', JSON.stringify(conversations));
  }, [conversations]);

  useEffect(() => {
    localStorage.setItem('sentencesForStudy', JSON.stringify(sentencesForStudy));
  }, [sentencesForStudy]);
  useEffect(() => {
    localStorage.setItem('sentencesForStudyForSpeak', JSON.stringify(sentencesForStudyForSpeak));
  }, [sentencesForStudyForSpeak]);
  
  useEffect(() => {
    localStorage.setItem('sentencesForExercise', JSON.stringify(sentencesForExercise));
  }, [sentencesForExercise]);

  useEffect(() => {
    localStorage.setItem('exercises', JSON.stringify(exercises));
  }, [exercises]);
  useEffect(() => {
    localStorage.setItem('instructionForExercise', JSON.stringify(instructionForExercise));
  }, [instructionForExercise]);  
  
  useEffect(() => {
    localStorage.setItem('levels', JSON.stringify(levels));
  }, [levels]);

  useEffect(() => {
    localStorage.setItem('topics', JSON.stringify(topics));
  }, [topics]);

  useEffect(() => {
    localStorage.setItem('allTopics', JSON.stringify(allTopics));
  }, [allTopics]);

  const sendFeedback = async (message) => {
    try {
      const response = await axios.post('http://localhost:5005/submit-feedback', { message });
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  const fetchLevels = async (language = '') => {
    try {
      const response = await axios.get('/api/levels', {
        params: { language },
      });
      setLevels(response.data);
    } catch (error) {
      console.error('Error fetching levels:', error);
    }
  };
  const fetchSearchLevel = async (topic_name_en = '') => {
    try {
      const response = await axios.get('/api/searchLevel', {
        params: { topic_name_en },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching themes:', error);
    }
  };

  const fetchTopicFromEnglishTopic = async (englishTopic = '', selectedLanguage = '') => {
    try {
      const response = await axios.get('/api/topicFromEnglishTopic', {
        params: { englishTopic, selectedLanguage },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };
  
  const fetchTopics = async (level = 0) => {
    try {
      const response = await axios.get('/api/topics', {
        params: { level },
      });
      setTopics(response.data);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const fetchAllTopics = async () => {
    try {
      const response = await axios.get('/api/allTopics');
      setAllTopics(response.data);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const fetchExercisesGroups = async (level = 0) => {
    try {     
        const response = await axios.get('/api/exercisesGroups', {
          params: { level},
        });
        setExercises(response.data);
    } catch (error) {
      console.error('Error fetching sentences:', error);
    }
  };
  
  const fetchExerciseSentences = async (language = '', exercise_name = '') => {
    try {     
        const response = await axios.get('/api/exerciseSentences', {
          params: { language, exercise_name },
        });
        setSentencesForExercise(response.data.sentencesForExercise);
        setInstructionForExercise(response.data.instructionForExercise);
    } catch (error) {
      console.error('Error fetching sentences:', error);
    }
  };

  const fetchConversations = async (language = '', topic = '') => {
    try {
        const response = await axios.get('/api/conversations', {
          params: { language, topic },
        });
        setConversations(response.data.conversations);
        setSentencesForStudy(response.data.sentencesForStudy);
        setSentencesForStudyForSpeak(response.data.sentencesForStudyForSpeak);
        setEndWithAI(response.data.endWithAI);
    } catch (error) {
      console.error('Error fetching sentences:', error);
    }
  };

  const fetchUpdatedVocab = async (vocabTerm) => {
    try {
      const response = await axios.get('/api/updateVocab', {
        params: { vocabTerm },
      });  
      return response.data;
    } catch (error) {
      console.error('Error updating vocabulary:', error);
    }
  };

  const fetchUpdatedMatchedPhrase = async (matchedPhrase) => {
    try {
      const response = await axios.get('/api/updateMatchedPhrase', {
        params: { matchedPhrase },
      });  
      return response.data;
    } catch (error) {
      console.error('Error updating vocabulary:', error);
    }
  };

  const fetchMyVocab = async (favoriteArray) => {
    try {
      const response = await axios.get('/api/myVocab', {
        params: { favoriteArray },
      });
      setMyVocab(response.data);
    } catch (error) {
      console.error('Error updating vocabulary:', error);
    }
  };
  
  const fetchGrammarGroups = async () => {
    const response = await axios.get('/api/grammar-groups');
    setGrammarGroups(response.data);
  };

  const fetchGrammarTopics = async (groupName = '') => {
    const response = await axios.get('/api/grammar-topics/', {
      params: { groupName },
    });
    setGrammarTopics(response.data);
  };
    
  const fetchGrammarContent = async (topicName = '', selectedLanguage = '') => {
    const response = await axios.get('/api/grammar-content', {
      params: { topicName,  selectedLanguage },
    });
    setGrammarContent(response.data);
  };

  const fetchPronunciationGroups = async () => {
    const response = await axios.get('/api/pronunciation-groups');
    setPronunciationGroups(response.data);
  };

  const fetchPronunciationTopics = async (groupId = 0) => {
    const response = await axios.get('/api/pronunciation-topics/', {
      params: { groupId },
    });
    setPronunciationTopics(response.data);
  };
    
  const fetchPronunciationContentFromTopic = async (topicId = 0, selectedLanguage = '') => {
    const response = await axios.get('/api/pronunciation-content-from-topic', {
      params: { topicId, selectedLanguage },
    });
    setPronunciationContent(response.data);
  };

  const fetchPronunciationContentFromGroup = async (groupId = 0, selectedLanguage='') => {
    const response = await axios.get('/api/pronunciation-content-from-group', {
      params: { groupId, selectedLanguage },
    });
    setPronunciationContent(response.data);
  };

  return (
    <DataContext.Provider
      value={{ fetchUpdatedMatchedPhrase, fetchSearchLevel, sendFeedback,  levels, fetchLevels, topics, fetchTopics, exercises, instructionForExercise, sentencesForExercise, setSentencesForExercise, sentencesForStudy, sentencesForStudyForSpeak, conversations, endWithAI, fetchExercisesGroups, fetchExerciseSentences, fetchConversations, fetchUpdatedVocab, grammarGroups, fetchGrammarGroups, grammarTopics, fetchGrammarTopics, grammarContent, setGrammarContent, fetchGrammarContent, pronunciationGroups, fetchPronunciationGroups, pronunciationTopics, fetchPronunciationTopics, pronunciationContent, setPronunciationContent, fetchPronunciationContentFromTopic, fetchPronunciationContentFromGroup, fetchTopicFromEnglishTopic, fetchAllTopics, allTopics, setSentencesForStudy, setSentencesForStudyForSpeak,  setConversations, setTopics, myVocab, fetchMyVocab, setGrammarTopics, setPronunciationTopics }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
