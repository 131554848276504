import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
//import { conversations } from './Conversations';
import DataContext from '../Reusables/DataContext';
import { useUserAnswers } from './UserAnswers';
import { showSweetAlert, showLastSweetAlert, getMatchPercentage, translations, getTranslatedSentence, getPointsEarned } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import AnswerInput from '../Reusables/AnswerInput';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import BottomMenuComponent from '../Reusables/BottomMenuComponent';
import './Practice.css'; 

const PracticeWriting = () => {
  let navigate = useNavigate();
  const { conversations, endWithAI } = useContext(DataContext);   
  const { selectedLanguage } = useLanguage();

  useEffect(() => {
    if (conversations.length > 0){
      setVisiblePairs([conversations[conversationIndex].pairs[currentIndex]]); // Start with the first pair visible
      setSituationDescription(conversations[conversationIndex].description);
    } 
  }, []);
  
  /********** CONVERSATION **********/ 
  const [conversationIndex, setConversationIndex] = useState(() => Math.floor(Math.random() * conversations.length)); 
  const [currentIndex, setCurrentIndex] = useState(0); // which pair of application-user 
  // const [visiblePairs, setVisiblePairs] = useState([conversations[conversationIndex].pairs[currentIndex]]);
  const [visiblePairs, setVisiblePairs] = useState([]);
  const [showDescription, setShowDescription] = useState(true);  
  const [answersIndex, setAnswersIndex] = useState(null);     // which answer
  const [answersIdx, setAnswersIdx] = useState(null);     // which answer
  const [answer, setAnswer] = useState('');
  // const [situationDescription, setSituationDescription] = useState(conversations[conversationIndex].description);
  const [situationDescription, setSituationDescription] = useState('');
  
  useEffect(() => {
    if (conversations.length > 0){
      setSituationDescription(conversations[conversationIndex].description);
      updateWriteConversationIndex(conversationIndex);
      setCurrentIndex(0);    
      setVisiblePairs([conversations[conversationIndex].pairs[0]]); 
      setAnswersIndex(null);
      setAnswersIdx(null);
      setTranslationIndex(null);
      setTranslationText('');
      setUserTranslationIndex(null);
      setUserTranslationIdx(null);
      setUserTranslationText(''); 
    }
  }, [conversationIndex]);

  useEffect(() => {
    if (currentIndex === 0){
      resetUserWriteAnswers(conversationIndex);
    } 
    if (conversations.length > 0){
      if (endWithAI && currentIndex === (conversations[conversationIndex].pairs.length - 1)) {
        const timer = setTimeout(() => {
          navigate('/grammar-report'); 
        }, 10000);
        return () => clearTimeout(timer);
      }
    }
  }, [currentIndex]); 

  const changeSituation = () => {
    const nextConversationIndex = conversationIndex + 1 < conversations.length ? conversationIndex + 1 : 0;
    setConversationIndex(nextConversationIndex);
  }
  const toggleShowAnswer = (index,idx) => {
    if (answersIndex === index && answersIdx === idx) {
      setAnswersIndex(null);
      setAnswersIdx(null);
    } else{
      setAnswersIndex(index);   
      setAnswersIdx(idx);  
    }           
  };
  
  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const handleAnswerChange = (newAnswer) => {
    setAnswer(newAnswer);
  };

  const handleAnswerSubmit = () => {        
    if (endWithAI && currentIndex === (conversations[conversationIndex].pairs.length - 1)) {      
      navigate('/grammar-report'); 
    } else {
      if (!userInteracted) { setUserInteracted(true); }
      findBestMatch();
    }    
  };
  
  const nextConversationPair = () => {
    const nextIndex = currentIndex + 1; 
    if (nextIndex < conversations[conversationIndex].pairs.length) {
      setVisiblePairs(prevVisiblePairs => [...prevVisiblePairs, conversations[conversationIndex].pairs[nextIndex]]);    
      setCurrentIndex(nextIndex);
    } 
  };

  //auto scroll to the latest conversation pair
  const messagesEndRef = useRef(null);
  useEffect(() => {
    const scrollToEnd = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Use setTimeout to ensure the SweetAlert has rendered before scrolling
    const timeoutId = setTimeout(scrollToEnd, 100);

    return () => clearTimeout(timeoutId);
  }, [visiblePairs]);

  /********** TRANSCRIPTION **********/ 
  const [transcriptionText, setTranscriptionText] = useState('');
  const [transcriptionIndex, setTranscriptionIndex] = useState(null); 

  const transribeApplication = (text, index) => {
    if (transcriptionIndex === index) {
      setTranscriptionIndex(null);
      setTranscriptionText(''); // Clear transcription text
    } else {
      setTranscriptionIndex(index);
      setTranscriptionText(text); // Set transcription text
    }
  };
  
  /********** TRANSLATION **********/ 
  const [translationText, setTranslationText] = useState('');
  const [translationIndex, setTranslationIndex] = useState(null); 
  const [userTranslationText, setUserTranslationText] = useState('');
  const [userTranslationIndex, setUserTranslationIndex] = useState(null); 
  const [userTranslationIdx, setUserTranslationIdx] = useState(null); 

  const translateApplication = (text, index) => {
    if (translationIndex === index) {
      setTranslationIndex(null);
      setTranslationText(''); // Clear translation text
    } else {
      setTranslationIndex(index);
      setTranslationText(text); // Set translation text
    }
  };  

  const translateSuggestAnswer = (text, index, idx) => {
    if(userTranslationIndex === index && userTranslationIdx === idx){
      setUserTranslationIndex(null);
      setUserTranslationIdx(null);
      setUserTranslationText(''); 
    } else{
      setUserTranslationIndex(index);
      setUserTranslationIdx(idx);
      setUserTranslationText(text);
    }
  };

  /********** MATCH USER ANSWER **********/ 
  const [highestMatch, setHighestMatch] = useState([0]);
  const {updateWriteConversationIndex, updateUserWriteAnswer, updateWriteMatchPercentage, updateWriteMatchSuggestResponse, resetUserWriteAnswers , updatePoints, updateUserReports} = useUserAnswers();
  const [bestMatchSentence, setBestMatchSentence] = useState(['']);
  const [userInteracted, setUserInteracted] = useState(false);
  let matchPercentage = '';
    
  function findBestMatch() {
    const sentencesA = visiblePairs[currentIndex].suggestResponses;
    let newHighestMatches = [...highestMatch]; // Clone the current state to modify

    sentencesA.forEach(sentence => {
        matchPercentage = getMatchPercentage(answer, sentence.fr);        
              
        if (currentIndex >= newHighestMatches.length) {
          newHighestMatches.push(0);  // Extend the highestMatch array if new answer          
        }

        // Update the highest match if the new match is better
        if (matchPercentage >= newHighestMatches[currentIndex]) {
          newHighestMatches[currentIndex] = matchPercentage;
          setBestMatchSentence(sentence.fr);

          
        }             
    });    
    
    // Update the state only once after all calculations are done
    setHighestMatch(newHighestMatches);
  }  

  useEffect(() => { 
    if(userInteracted){    
      const audio = new Audio('/Audio/writing-reply.wav');
      audio.play().catch((error) => console.error('Audio playback failed:', error));

      updateUserWriteAnswer(conversationIndex, currentIndex, answer); 
      updateWriteMatchPercentage(conversationIndex, currentIndex, highestMatch[currentIndex]); 
      updateWriteMatchSuggestResponse(conversationIndex, currentIndex, bestMatchSentence);
      updatePoints(getPointsEarned(bestMatchSentence, matchPercentage));
      let value = highestMatch[currentIndex];
      let message;
      let iconUrl;

      switch (true) {
        case (value < 10):
          message = translations[selectedLanguage].DontGiveUp; 
          iconUrl = 'Icons/dontgiveup.svg'; 
          break;
        case (value >= 10 && value < 20):
          message = translations[selectedLanguage].YouCanDoIt; 
          iconUrl = 'Icons/youcandoit.svg';   
          break;
        case (value >= 20 && value < 30):
          message = translations[selectedLanguage].KeepPracticing;
          iconUrl = 'Icons/keeppracticing.svg';
          break;
        case (value >= 30 && value < 40):
          message = translations[selectedLanguage].KeepGoing;
          iconUrl = 'Icons/keepgoing.svg';  
          break;
        case (value >= 40 && value < 50):
          message = translations[selectedLanguage].NotBad
          iconUrl = 'Icons/notbad.svg'; 
          break;
        case (value >= 50 && value < 60):
          message = translations[selectedLanguage].GoodJob;
          iconUrl = 'Icons/goodjob.svg';
          break;
        case (value >= 60 && value < 70):
          message = translations[selectedLanguage].Bravo;
          iconUrl = 'Icons/bravo.svg';
          break;
        case (value >= 70 && value < 75):
          message = translations[selectedLanguage].Super;
          iconUrl = 'Icons/super.svg';
          break;
        case (value >= 75 && value < 80):
          message = translations[selectedLanguage].Excellent;
          iconUrl = 'Icons/excellent.svg';
          break;
        case (value >= 80 && value < 90):
          message = translations[selectedLanguage].Wow;
          iconUrl = 'Icons/wow.svg';
          break;
        case (value >= 90):
          message = translations[selectedLanguage].Pro;
          iconUrl = 'Icons/pro.svg';
          break;
        default:
          message = translations[selectedLanguage].OutOfRange;
      }     

      if (currentIndex < conversations[conversationIndex].pairs.length - 1) {
        showSweetAlert(message, 'practice-message', iconUrl);
      } else if (currentIndex === conversations[conversationIndex].pairs.length - 1){
        showLastSweetAlert(message, 'practice-message', iconUrl, navigate, '/grammar-report');
      }

      if (currentIndex === conversations[conversationIndex].pairs.length - 1 ||
        (endWithAI && currentIndex === conversations[conversationIndex].pairs.length - 2)
      ) {
        updateUserReports(selectedLanguage, 2);     // MANUAL INPUT
      }

      setAnswer(''); 
      nextConversationPair();   
    }           
  }, [ highestMatch]); // This effect runs only when `userAnswers` changes  

  /********** POPUP INSTRUCTION **********/
  const situationRef = useRef(null);
  const changeRef = useRef(null);  
  const transcriptionRef = useRef(null); 
  const optionRef = useRef(null);
  const typeRef = useRef(null);

  const steps = [
    { ref: situationRef, label: translations[selectedLanguage].SituationLabel, text: translations[selectedLanguage].collapseSituationDescription },
    { ref: changeRef, label: translations[selectedLanguage].changeLabel, text: translations[selectedLanguage].changeSituation},
    { ref: optionRef, label: translations[selectedLanguage].Options, text: translations[selectedLanguage].possibleAnswers },
    { ref: typeRef, label: translations[selectedLanguage].Inputarea, text: translations[selectedLanguage].Writeyouranswers },    
  ];

  /********* dynamically adjust the viewport height on mobile *********/
  useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };
  
    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initially
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);  

  /********** INTERFACE **********/ 
  return (
    <div >
      {/* {conversations.length > 0 ? ( */}
      <div className="practice-page">
      <div className="top-row">
        <button onClick={() => navigate(-1)} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>
        <h3>{translations[selectedLanguage].PracticeWriting}</h3>
        <BurgerMenuComponent />     
      </div> 

      <div className="practice-body">      
      <div style={{display:'flex', justifyContent: 'space-between', marginTop:'5px' }}>
        <button ref={situationRef} className="situation-button" onClick={toggleDescription}>
            <div style={{marginRight:"auto"}}>{translations[selectedLanguage].Situation}</div>
            &nbsp;&nbsp;
            <div style={{marginLeft:"auto"}}>{showDescription ? ( 
                <img src={`/Icons/arrow-up.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].collapse} />
            ) : (
                <img src={`/Icons/arrow-down.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].expand} />
            )}</div>
        </button>        
        <button ref={changeRef} className="situation-change" onClick={changeSituation}>
            <div style={{marginRight:"auto"}}>{translations[selectedLanguage].change}</div>
            &nbsp;&nbsp;&nbsp;
            <img src={`/Icons/change.svg?${new Date().getTime()}`} alt="" />       
        </button>
      </div>
      {showDescription && 
        <div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} className="situation" style={{ marginTop:'5px' }}>{situationDescription.split('<br/>').map((line, index, array) => (
            <React.Fragment key={index}>
            {line}
            {index < array.length - 1 && <br />}
            </React.Fragment>
        ))}</div> 
      }

      <div className="practice-container">
      <div className="conversation-dialog-container" >
      {visiblePairs.map((pair, index) => (        
      <div key={index} className="conversation-dialog">
        <span  className="conversation-label" style={{marginRight:'auto'}}>{translations[selectedLanguage].AI}</span>
        <div className="application-user-container">
          <div className="application-answer">            
            <div >   
              <button style={{marginLeft: '0px'}}  className="translation-button-practice" onClick={() => translateApplication(getTranslatedSentence(pair.application, selectedLanguage), index)}>  
                  <img src={translationIndex === index && translationText !== '' ? "Icons/translation-practice-active.svg" : "Icons/translation-practice.svg"} alt={translations[selectedLanguage].Translation} />
              </button>                             
            </div>               
            {/* {(transcriptionIndex === index ||  (translationIndex === index && translationText !== '')) && <hr/>}              */}
            <div style={{color:'#ffffff'}}>{pair.application.fr}</div>           
            {translationIndex === index && (
              <div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} style={{color:'#E07F9C'}}>{translationText}</div>
            )}   
          </div>  
        </div>

        <span className="conversation-label" style={{marginLeft:'auto'}}>{translations[selectedLanguage].User}</span>
        <div className="user-answers-container"> 
        {(selectedLanguage === 'ar' || selectedLanguage === 'fa') ?
          <div className="user-answers">
            <div dir='rtl' style={{color:'#E07F9C'}}><strong>{translations[selectedLanguage].HINT}: </strong>{pair.hint}</div>
            <hr />
            {pair.suggestResponses.map((response, idx) => (
              <div key={idx} >
                { idx < 3 && (<>
                <div className="user-answer">                                                            
                <button style={{marginLeft:'0px'}} className="translation-button-practice" onClick={() => translateSuggestAnswer(getTranslatedSentence(response, selectedLanguage), index, idx)}>
                  <img src={userTranslationIndex === index && userTranslationIdx === idx && userTranslationText !== '' ? "Icons/translation-practice-active.svg" : "Icons/translation-practice.svg"} alt={translations[selectedLanguage].Translation} />
                </button>   
                <button style={{marginRight:'20px'}} className="transcription-button-practice" onClick={() => toggleShowAnswer(index, idx)}>
                  <img  src={answersIndex === index && answersIdx === idx ? "Icons/transcription-practice-user-active.svg" : "Icons/transcription-practice.svg"} alt={translations[selectedLanguage].Transcription} />
                </button>  
                <div dir='rtl' ref={optionRef} ><b>{translations[selectedLanguage].Option}{` ${idx + 1}: `}</b></div>                         
                </div>                 
                {userTranslationIndex === index && userTranslationIdx === idx && (
                  <div dir='rtl' style={{color:'#E07F9C'}}>{userTranslationText}</div>
                )}  
                {answersIndex === index && answersIdx === idx && (
                  <div style={{color:'#062E5F'}}>{response.fr}</div>                  
                )}                              
                {idx < Math.min(2, pair.suggestResponses.length - 1) && <hr />}
                </>)}                   
              </div>
            ))}            
          </div>
        :
          <div className="user-answers">
            <div style={{color:'#E07F9C'}}><strong>{translations[selectedLanguage].HINT}: </strong>{pair.hint}</div>
            <hr />
            {pair.suggestResponses.map((response, idx) => (
              <div key={idx} >
                { idx < 3 && (<>
                <div className="user-answer">
                <div ref={optionRef} ><b>{translations[selectedLanguage].Option}{` ${idx + 1}: `}</b></div>                                              
                <button className="translation-button-practice" onClick={() => translateSuggestAnswer(getTranslatedSentence(response, selectedLanguage), index, idx)}>
                  <img src={userTranslationIndex === index && userTranslationIdx === idx && userTranslationText !== '' ? "Icons/translation-practice-active.svg" : "Icons/translation-practice.svg"} alt={translations[selectedLanguage].Translation} />
                </button>   
                <button className="transcription-button-practice" onClick={() => toggleShowAnswer(index, idx)}>
                  <img  src={answersIndex === index && answersIdx === idx ? "Icons/transcription-practice-user-active.svg" : "Icons/transcription-practice.svg"} alt={translations[selectedLanguage].Transcription} />
                </button>                         
                </div>                 
                {userTranslationIndex === index && userTranslationIdx === idx && (
                  <div style={{color:'#E07F9C'}}>{userTranslationText}</div>
                )}  
                {answersIndex === index && answersIdx === idx && (
                  <div style={{color:'#062E5F'}}>{response.fr}</div>                  
                )}                              
                {idx < Math.min(2, pair.suggestResponses.length - 1) && <hr />}
                </>)}                   
              </div>
            ))}            
          </div>
        }         
          
        </div>
      </div>
      ))}
      <div ref={messagesEndRef} />
      </div></div>
      
      <div ref={typeRef} style={{marginBottom:'50px'}}><AnswerInput 
        answer={answer}
        onAnswerChange={handleAnswerChange}
        onAnswerSubmit={handleAnswerSubmit}
      /></div>  
      </div>  
      <BottomMenuComponent steps={steps}/>
      </div>      
        {/* ) : (
          <button className="topic-option" onClick={() => navigate('/')}>{translations[selectedLanguage].PleaseChooseALevel}</button>        
        )}  */}
    </div>
  );
};

export default PracticeWriting;