import React , {useContext} from 'react';
import { translations } from './Utilities';
import { useLanguage } from './LanguageProvider';
import { useNavigate } from 'react-router-dom';
import FlagComponent from './FlagComponent';
import HelpComponent from './HelpComponent';
import { useRecordUser } from './RecordUser';
import { useUserAnswers } from '../Practice/UserAnswers';
import './Reusables.css'; 

const BottomMenuComponent = React.memo(({ steps }) => {
  const { selectedLanguage } = useLanguage();
  const navigate = useNavigate();
  const {closeAudio, setTranscription} = useRecordUser();  
  const {topic} = useUserAnswers();

  const goToStudy = () => {  
    setTranscription('');    
    navigate('/speaking-sentences');
  };

  const goToSummary = () => {      
    closeAudio();      
    navigate('/summary-page');
  };

  const goToPractice = () => { 
    closeAudio();
    setTranscription(''); 
    navigate('/practice');           
  };

  return (
    <div className="bottom-menu">
      <button className="menu-icon" onClick={goToStudy}>
        <img style={{width:'32px', height:'30px'}}  src="/Icons/study-bottome-menu.svg" alt={translations[selectedLanguage].study} />
      </button>
      <button className="menu-icon" onClick={goToSummary}>
        <img style={{width:'26px', height:'26px'}}  src="/Icons/summary-bottome-menu.svg" alt={translations[selectedLanguage].summary} />
      </button>
      {topic !== '' ? 
      <button className="menu-icon" onClick={goToPractice}>
        <img style={{width:'25px', height:'25px'}}  src={ "/Icons/practice-bottom-menu.svg"} alt={translations[selectedLanguage].practice} />
      </button>
      :
      <button className="menu-icon" onClick={goToPractice}>
        <img style={{width:'25px', height:'25px'}}  src={"/Icons/instruction.svg"} alt={translations[selectedLanguage].practice} />
      </button>}      
      <HelpComponent steps={steps} />       
      <FlagComponent/>        
    </div>
  );
});

export default BottomMenuComponent;
