import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Title } from 'chart.js';
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Title);

const BarChart = ({ data, onBarClick }) => {
  const chartData = {
    labels: data.map((item) => item.period),
    datasets: [
      {
        label: '',
        data: data.map((item) => item.averageScore),
        backgroundColor: '#66D5C1',
        barPercentage: 0.5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: { stepSize: 25 },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw} %`, // Custom tooltip to show percentage
        },
      }
    },
    onClick: (_, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        onBarClick(data[index].reports);
      }
    },
  };

  const chartContainerStyle = {
    width: '100%',
    // maxWidth: '900px',
    height: '320px',
    overflowX: 'auto', // Allow horizontal scrolling
    overflowY: 'hidden', // Prevent vertical scrolling
    whiteSpace: 'nowrap', // Ensure the bars stay in a single row
  };

  return (
    <div style={chartContainerStyle}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarChart;
