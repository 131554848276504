import React, { createContext, useState, useContext, useEffect } from 'react';
import { calculateAverageMatch } from '../Reusables/Utilities';
// Create the context
const UserAnswersContext = createContext({
  });

// Hook to use the context
export const useUserAnswers = () => useContext(UserAnswersContext);

// Context provider component
export const UserAnswersProvider = ({ children }) => {
    const [studyIndex, setStudyIndex] = useState(() => {      // sentencesForStudy or sentencesForExercise index
        return localStorage.getItem('studyIndex') || 0;        // Load from localStorage
      });
    const [level, setLevel] = useState(() => {              // level_id
      return Number(localStorage.getItem('level')) || 0;    // convert to number
    });
    const [exercise, setExercise] = useState(() => {
        return localStorage.getItem('exercise') || ''; 
      });
    const [topic, setTopic] = useState(() => {
      return localStorage.getItem('topic') || ''; 
    });
    const [englishTheme, setEnglishTheme] = useState(() => {
      return localStorage.getItem('englishTheme') || ''; 
    });
    const [englishTopic, setEnglishTopic] = useState(() => {
      return localStorage.getItem('englishTopic') || ''; 
    });
    const [speakConversationIndex, setSpeakConversationIndex] = useState(() => {
      return localStorage.getItem('speakConversationIndex') || 0; 
    });
    const [userSpeakAnswers, setUserSpeakAnswers] = useState(() => {
        const savedAnswers = localStorage.getItem('userSpeakAnswers');
        return savedAnswers ? JSON.parse(savedAnswers) : [
        {
            pairs: [
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
              ],
        },
        {
            pairs: [
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
              ],
        },
        {
            pairs: [
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
              ],
        },    
    ]; // Load answers as array
    });
    const [writeConversationIndex, setWriteConversationIndex] = useState(() => {
        return localStorage.getItem('writeConversationIndex') || 0; 
    });
    const [userWriteAnswers, setUserWriteAnswers] = useState(() => {
        const savedAnswers = localStorage.getItem('userWriteAnswers');
        return savedAnswers ? JSON.parse(savedAnswers) : [
            {
                pairs: [
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                  ],
            },
            {
                pairs: [
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                  ],
            },
            {
                pairs: [
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                    { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
                  ],
            },    
    ]; // Load answers as array
    });
    const [points, setPoints] = useState(() => {      // is level_id
        return parseInt(localStorage.getItem('points'), 10) || 0; // Load from localStorage
      }); 
    const [userReports, setUserReports] = useState(() => {
        const savedAnswers = localStorage.getItem('userReports');
        return savedAnswers ? JSON.parse(savedAnswers) : [
            // {
            //     date: new Date().toISOString(), // Store date in ISO 8601 format
            //     level: 0,                
            //     englishTopic: '',
            //     selectedLanguage: '',   //if the same as language used by people seeing reports, show topic, else show englishTopic or fetch from database
            //     topic: '',
            //     skillID: 0,
            //     score: 0,
            //     pairs: [
            //         { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
            //         ],
            // },
        ];
    });   
    const [filteredReports, setFilteredReports] = useState(() => {
        const savedAnswers = localStorage.getItem('filteredReports');
        return savedAnswers ? JSON.parse(savedAnswers) : [];
    });  

    // Save in localStorage whenever it changes    
    useEffect(() => {
        localStorage.setItem('studyIndex', studyIndex);
      }, [studyIndex]);
    useEffect(() => {
      localStorage.setItem('level', level);
    }, [level]);
    useEffect(() => {
        localStorage.setItem('exercise', exercise);
      }, [exercise]);
    useEffect(() => {
      localStorage.setItem('topic', topic);
    }, [topic]);
    useEffect(() => {
        localStorage.setItem('englishTheme', englishTheme);
    }, [englishTheme]);
    useEffect(() => {
      localStorage.setItem('englishTopic', englishTopic);
    }, [englishTopic]);
    useEffect(() => {
        localStorage.setItem('speakConversationIndex', speakConversationIndex);
    }, [speakConversationIndex]);
    useEffect(() => {
        localStorage.setItem('userSpeakAnswers', JSON.stringify(userSpeakAnswers));
    }, [userSpeakAnswers]);
    useEffect(() => {
        localStorage.setItem('writeConversationIndex', writeConversationIndex);
    }, [writeConversationIndex]);
    useEffect(() => {
        localStorage.setItem('userWriteAnswers', JSON.stringify(userWriteAnswers));
    }, [userWriteAnswers]);
    useEffect(() => {
        localStorage.setItem('points', points);
      }, [points]);
    useEffect(() => {
        localStorage.setItem('userReports',  JSON.stringify(userReports));
    }, [userReports]);
    useEffect(() => {
        localStorage.setItem('filteredReports',  JSON.stringify(filteredReports));
    }, [filteredReports]);
   
    const updateStudyIndex = (newStudyIndex) => setStudyIndex(newStudyIndex);
    const updateLevel = (newLevel) => setLevel(newLevel);
    const updateExercise = (newExercise) => setExercise(newExercise);
    const updateTopic = (newTopic) => setTopic(newTopic);
    const updateEnglishTheme = (newEnglishTheme) => setEnglishTheme(newEnglishTheme);
    const updateEnglishTopic = (newEnglishTopic) => setEnglishTopic(newEnglishTopic);
    const updateSpeakConversationIndex = (newSpeakConversationIndex) => setSpeakConversationIndex(newSpeakConversationIndex);
    const updateWriteConversationIndex = (newWriteConversationIndex) => setWriteConversationIndex(newWriteConversationIndex);
    
    const updatePoints = (increment) => {
        setPoints((prevPoints) => {
            const newPoints = prevPoints + increment; // Safely calculate the new points
            localStorage.setItem('points', newPoints); // Update localStorage
            return newPoints;
        });
      };
      
    const updateUserSpeakAnswer = (conversationIndex, pairIndex, newUserAnswer) => {
        setUserSpeakAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                userAnswer: newUserAnswer,
            };
            return newUserAnswers;
        });
    };

    const updateSpeakMatchPercentage = (conversationIndex, pairIndex, newMatchPercentage) => {
        setUserSpeakAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                matchPercentage: newMatchPercentage,
            };
            return newUserAnswers;
        });
    };

    const updateSpeakMatchSuggestResponse = (conversationIndex, pairIndex, newMatchSuggestResponse) => {
        setUserSpeakAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                matchSuggestResponse: newMatchSuggestResponse,
            };
            return newUserAnswers;
        });
    };

    const updateUserWriteAnswer = (conversationIndex, pairIndex, newUserAnswer) => {
        setUserWriteAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                userAnswer: newUserAnswer,
            };
            return newUserAnswers;
        });
    };

    const updateWriteMatchPercentage = (conversationIndex, pairIndex, newMatchPercentage) => {
        setUserWriteAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                matchPercentage: newMatchPercentage,
            };
            return newUserAnswers;
        });
    };

    const updateWriteMatchSuggestResponse = (conversationIndex, pairIndex, newMatchSuggestResponse) => {
        setUserWriteAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                matchSuggestResponse: newMatchSuggestResponse,
            };
            return newUserAnswers;
        });
    };

    const resetUserSpeakAnswers = (conversationIndex) => {
        setUserSpeakAnswers(prevUserAnswers => 
            prevUserAnswers.map((conversation, index) => 
                index === conversationIndex 
                    ? {
                        ...conversation,
                        pairs: [
                            {
                                userAnswer: '',
                                matchPercentage: 0,
                                matchSuggestResponse: ''
                            }
                        ]
                      }
                    : conversation
            )
        );
    };   

    const resetUserWriteAnswers = (conversationIndex) => {
        setUserWriteAnswers(prevUserAnswers => 
            prevUserAnswers.map((conversation, index) => 
                index === conversationIndex 
                    ? {
                        ...conversation,
                        pairs: [
                            {
                                userAnswer: '',
                                matchPercentage: 0,
                                matchSuggestResponse: ''
                            }
                        ]
                      }
                    : conversation
            )
        );
    };

    const updateUserReports = (selectedLanguage, skillID) => {
        setUserReports(prevUserReports => {
            const newUserReports = [...prevUserReports];
    
            // Get the current date in ISO 8601 format
            const currentDate = new Date().toISOString(); 
            let score = 0;
    
            // Determine pairs based on skillID
            let pairs = [];
            if (skillID === 1) {
                pairs = userSpeakAnswers[speakConversationIndex]?.pairs || [];
                score = parseInt(calculateAverageMatch(userSpeakAnswers[speakConversationIndex]).toFixed(0), 10); // Convert to number
            } else if (skillID === 2) {
                pairs = userWriteAnswers[writeConversationIndex]?.pairs || []; 
                score = parseInt(calculateAverageMatch(userWriteAnswers[writeConversationIndex]).toFixed(0), 10); // Convert to number
            }
    
            // Create a new report entry
            const newReport = {
                date: currentDate,
                level: level,              
                englishTopic: englishTopic,  
                selectedLanguage: selectedLanguage,
                topic: topic,               
                skillID: skillID,
                score: score,
                pairs: pairs,             
            };
    
            // Append the new report to the reports array
            newUserReports.push(newReport);
    
            return newUserReports;
        });
    };
    

    return (
        <UserAnswersContext.Provider value={{  
            studyIndex,
            level, 
            exercise,
            topic,
            englishTheme,
            englishTopic,
            updateStudyIndex,
            updateLevel, 
            points,
            userReports,
            filteredReports,
            setFilteredReports,
            updateExercise,
            updateTopic,
            updateEnglishTheme,
            updateEnglishTopic,
            speakConversationIndex,
            updateSpeakConversationIndex,
            userSpeakAnswers,
            updateUserSpeakAnswer,
            updateSpeakMatchPercentage,
            updateSpeakMatchSuggestResponse,
            updatePoints,
            writeConversationIndex,
            updateWriteConversationIndex,
            userWriteAnswers,
            updateUserWriteAnswer,
            updateWriteMatchPercentage,
            updateWriteMatchSuggestResponse,
            resetUserSpeakAnswers,
            resetUserWriteAnswers,
            updateUserReports
        }}>
            {children}
        </UserAnswersContext.Provider>
    );
};
  
  
   // Function to update a specific pair's properties
   //to use: updateUserAnswer(0, 1, {
        //     userAnswer: 'Updated answer',
        //     matchPercentage: 75,
        //     matchSuggestResponse: 'New suggested response',
        // }
//    const updateUserAnswerRecord = (conversationIndex, pairIndex, data) => {
//     setUserAnswers(prevUserAnswers => {
//         const newUserAnswers = [...prevUserAnswers];
//         newUserAnswers[conversationIndex].pairs[pairIndex] = {
//             ...newUserAnswers[conversationIndex].pairs[pairIndex],
//             ...data, 
//         };
//         return newUserAnswers;
//     });