import React, {useEffect} from 'react';
import { useNavigate,  } from 'react-router-dom';
import { useUserAnswers } from '../Practice/UserAnswers';
import { highlightMatches, calculateAverageMatch, translations} from '../Reusables/Utilities';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import BottomMenuComponent from '../Reusables/BottomMenuComponent';
import { useLanguage } from '../Reusables/LanguageProvider';
import { useRecordUser } from '../Reusables/RecordUser';

const PronunciationReport = () => {
    let navigate = useNavigate();
    const {speakConversationIndex, userSpeakAnswers, englishTopic, level } = useUserAnswers();
    const {setTranscription} = useRecordUser();  
    const conversation = userSpeakAnswers[speakConversationIndex]; 
    const { selectedLanguage } = useLanguage();

    const goBack = () => {
        setTranscription('');
        navigate(-1);
    }
    
    useEffect(() => {
        const audio = new Audio('/Audio/report.wav');
        audio.play().catch((error) => console.error('Audio playback failed:', error));
    }, []);

    return (
        <div className="study-page">
        <div className="top-row">
            <button onClick={() => goBack()} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>        
            <h3 dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'}>{translations[selectedLanguage].YouScore} {calculateAverageMatch(conversation).toFixed(0)}%</h3>
            <BurgerMenuComponent />
        </div>  
        <div className="specific-report" >       
            <ul>
                {conversation.pairs.map((pair, pairIndex) => (
                    <div key={pairIndex} className="result-box">   
                        <div className="line-1">                    
                            <span className="label">{translations[selectedLanguage].Detected}:</span>
                            <span className="content">{highlightMatches(pair.matchSuggestResponse, pair.userAnswer)}</span>
                        </div>
                        <div className="line-2">  
                            <span className="label">{translations[selectedLanguage].Expected}:</span>
                            <span className="content">{pair.matchSuggestResponse}</span>                   
                        </div>
                    </div>           
                ))}
            </ul>  
            <div style={{marginTop:'30px', textAlign:'right'}}>
            <button data-language={selectedLanguage} data-level={level} data-topicspeak={englishTopic} className="pronunciation-report-next-button" onClick={() => navigate('/completion-page')}>
                <b>{translations[selectedLanguage].next}</b>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={"/Icons/arrow-right-aqua.svg"} alt={translations[selectedLanguage].next} className="arrow-right" />
            </button> 
            <br/><br/><br/><br/>
            </div> 
        </div>
        <BottomMenuComponent steps={[]}/>                  
        </div>
    );
};

export default PronunciationReport;
