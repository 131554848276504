import React, { useEffect, useRef, useState } from 'react';
import {translations } from './Utilities';
import { useLanguage } from './LanguageProvider';
import './Reusables.css'; // Ensure to import the CSS file

const AnswerInput = ({ answer, onAnswerChange, onAnswerSubmit }) => {
    const textareaRef = useRef(null);
    const { selectedLanguage } = useLanguage();
    const [showKeyboard, setShowKeyboard] = useState(false);

    const handleKeyboardInput = (char) => {
      onAnswerChange(answer + char);
    };

    const adjustHeight = () => {        
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto'; // Reset the height
            const newHeight = Math.min(textarea.scrollHeight, 150); // Set a max height
            textarea.style.height = newHeight + 'px'; // Apply new height with max limit
        }
    };

    // Adjust height whenever the 'answer' changes
    useEffect(() => {
        adjustHeight();
    }, [answer]);

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submit behavior
        onAnswerSubmit(); // Call the submit handler passed down from App
    };

    // enter to submit; shift + enter to create a new line
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevent the default behavior of inserting a new line
            handleSubmit(event); // Submit the form
        } else  {
            adjustHeight(); // Adjust the height in case of other keys
        }
    };

    return (
        <div>
        <div className="french-keyboard">
            <button onClick={() => setShowKeyboard(!showKeyboard)} className="toggle-keyboard-button">
                {/* {showKeyboard ? translations[selectedLanguage].HideKeyboard : translations[selectedLanguage].ShowKeyboard} */}
                {/* {translations[selectedLanguage].Keyboard}&ensp; */}
                é è ê ë&ensp;
                {showKeyboard ? (                  
                    <img src={`/Icons/arrow-left-keyboard.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].collapse} />
                ) : (
                    <img src={`/Icons/arrow-right-keyboard.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].expand} />
                )}
            </button>
            {showKeyboard && (                       
                ['é', 'à', 'è', 'ù', 'ç', 'â', 'ê', 'î', 'ô', 'û','ä', 'ë', 'ï', 'ü','œ', 'æ'].map((char) => (
                    <button
                    key={char}
                    type="button"
                    className="keyboard-key"
                    onClick={() => handleKeyboardInput(char)}
                    >
                    {char}
                    </button>
                ))
            )} 
        </div>
        <form onSubmit={handleSubmit} className="answer-form">       
            <textarea
                ref={textareaRef}
                value={answer}
                onChange={(e) => {
                    onAnswerChange(e.target.value);
                    adjustHeight();
                }}
                onKeyDown={handleKeyDown}
                placeholder= {translations[selectedLanguage].TypeAnswerHere}
                className="answer-input"
                rows="1" // Starts with a single line
            />
            <button type="submit" className="practice-write-submit-button">
                <img src="Icons/submit.svg" alt={translations[selectedLanguage].Submit} />
            </button>                   
        </form>
        </div>
    );
};

export default AnswerInput;