import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import DataContext from '../Reusables/DataContext';
import { useUserAnswers  } from './UserAnswers';
import { translations } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import BottomMenuComponent from '../Reusables/BottomMenuComponent';
import './Practice.css'; 

const PracticeSpeaking = () => {
  let navigate = useNavigate();    
  const { conversations, instructionForExercise } = useContext(DataContext);
  const { selectedLanguage } = useLanguage();
  const [conversationIndex, setConversationIndex] = useState(() => Math.floor(Math.random() * conversations.length));
  const [situationDescription, setSituationDescription] = useState('');
  const { exercise, topic, updateSpeakConversationIndex, updateWriteConversationIndex} = useUserAnswers();
   
  useEffect(() => {
    if (topic !== '' ){
      conversations.length === 0 ? 
        navigate('/')
      :
        setSituationDescription(conversations[conversationIndex].description);      
    }    
  }, [conversationIndex]);

  const goBack = () => {
    navigate(-1);    
  }
  
  const changeSituation = () => {
    const nextConversationIndex = conversationIndex + 1 < conversations.length ? conversationIndex + 1 : 0;
    setConversationIndex(nextConversationIndex);
  }

  const goToSpeak = () => {
    updateSpeakConversationIndex(conversationIndex);
    navigate('/practice-speaking');
  }

  const goToWrite = () => {
    updateWriteConversationIndex(conversationIndex);
    navigate('/practice-writing');
  }

  /********** POPUP INSTRUCTION **********/
  const changeRef = useRef(null);
  const situationRef = useRef(null);

  let steps = [];
  if (topic !== ''){
    steps = [
        { ref: situationRef, label: translations[selectedLanguage].SituationLabel, text: translations[selectedLanguage].situationDescription},
        { ref: changeRef, label: translations[selectedLanguage].changeLabel, text: translations[selectedLanguage].changeSituation}
      ];
  }  

  /********** INTERFACE **********/ 
  return (
    <div className="study-page">
      {(topic === '' && exercise === '') ? (
        <button className="topic-option" onClick={() => navigate('/')}>{translations[selectedLanguage].ChooseATopic}</button>    
      ) : (        
        <div>
        <div className="top-row">
          <button onClick={() => goBack()} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>
          <h3>{exercise !== '' ? (translations[selectedLanguage].exerciseInstruction) : translations[selectedLanguage].Practice}</h3>
          <BurgerMenuComponent />
        </div> 

        {topic !== '' ? 
        
        <div className="study-body">
          <div style={{display:'flex', justifyContent: 'space-between', marginTop:'20px', marginBottom:'30px' }}>
          <div className="situation-button">{translations[selectedLanguage].Situation}</div>
          <button ref={changeRef} className="situation-change" onClick={changeSituation}>
              <div style={{marginRight:"auto"}}>{translations[selectedLanguage].change}</div>
              &nbsp;&nbsp;&nbsp;
              <img src={`/Icons/change.svg?${new Date().getTime()}`} alt="" />       
          </button>
          </div>

          <div ref={situationRef} dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} className="situation">{situationDescription.split('<br/>').map((line, index, array) => (
              <React.Fragment key={index}>
              {line}
              {index < array.length - 1 && <br />}
              </React.Fragment>
          ))}</div> 

          <br/><br/><br/>
          <button style={{margin:'0px auto 30px auto', width: '100%', maxWidth: '500px'}} className="practice-speak-button" onClick={() => goToSpeak()}>
              <b>{translations[selectedLanguage].PracticeSpeaking}</b>
              <img style={{width:'22px', height:'25px', marginLeft:'auto'}} src={`/Icons/practice.svg?${new Date().getTime()}`} alt="" />
              </button>
          <button style={{margin:'0px auto 100px auto', width: '100%', maxWidth: '500px'}} className="practice-write-button" onClick={() => goToWrite()}>
              <b>{translations[selectedLanguage].PracticeWriting}</b>
              <img style={{width:'22px', height:'25px', marginLeft:'auto'}} src={`/Icons/pencil.svg?${new Date().getTime()}`} alt="" /></button>      
      </div>

      :

      <div className='grammar-content'>
        <div >
          <br/> <br/>
          <div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} dangerouslySetInnerHTML={{ __html: instructionForExercise }} />
          <br/><br/><br/>
        </div>
      </div>
      }
      <BottomMenuComponent steps={steps}/>
      </div>     
      )} 
    </div>
  );
};

export default PracticeSpeaking;